import Footer from '@/components/Footer/Footer'
import { getPageApi, getPagesApi } from '@/utils/api'
import { GetStaticPropsContext } from 'next'
import PageSection from '../src/components/PageSection'
import SEOComponent from '@/components/SEO'
import { useEffect } from 'react'
import { MARKETING_PAGE_VIEW_ANALYTICS_EVENT_NAME, useAmplitude } from '@/utils/hooks/useAmplitude'
import { PageParams } from '@/types/common'
import { GetPageQuery } from 'graphql/gql/wrapper/graphql'
import logger from '@/utils/logger'
import path from 'path'
import Header from '@/components/Header/HeaderCommon'
import { CmsPageSlugObjType, getAllPagesSlugs } from '@/utils/cmsUtils'
import { windowExists } from '@/utils/windowUtils'

export type SinglePageType = GetPageQuery['allMkMarketingPage'][0]
export type MkSEO = SinglePageType['mk_seo']

type SlugProps = {
  page: SinglePageType
  cmsSlugObj: CmsPageSlugObjType[]
}

const Slug = ({ page, cmsSlugObj }: SlugProps) => {
  const { mk_slug, mk_pageBuilder, mk_header, mk_footer, mk_pageNameAnalyticsEventProperty, mk_seo } = page
  const cmsSlugSet = new Set(cmsSlugObj.map((slug) => slug?.mk_slug?.current))

  const { logAmplitudeEvent, setPage, setCmsSlugSet } = useAmplitude()

  useEffect(() => {
    // Send page view event to Amplitude
    // Will send event twice on localhost because of reactStrictMode config in next.config.js
    logAmplitudeEvent(
      MARKETING_PAGE_VIEW_ANALYTICS_EVENT_NAME,
      null,
      { 'Page Name': mk_pageNameAnalyticsEventProperty ?? (mk_slug?.current as string) },
      { isPageViewEvent: true },
    )

    // Set state for use in interaction events
    setPage(mk_pageNameAnalyticsEventProperty ?? (mk_slug?.current as string))
    setCmsSlugSet(cmsSlugSet)
  }, [windowExists() && window.location.href])

  const pageSections = mk_pageBuilder?.map((pageItem, index) => (
    <PageSection details={pageItem as any} key={pageItem?.__typename + index.toString()} />
  ))
  return (
    <>
      {mk_seo && <SEOComponent seoData={{ mk_seo, slug: mk_slug?.current ?? '' }} />}
      <Header headerToDisplay={mk_header ?? 'None'} />

      {pageSections}
      {mk_footer && <Footer />}
    </>
  )
}

export const getStaticPaths = async () => {
  let offset = 0
  const limit = 500 // Set your desired limit
  let allPaths: { params: PageParams }[] = []

  while (true) {
    const pagesApiResponse = await getPagesApi(limit, offset)
    const currentPaths = pagesApiResponse.allMkMarketingPage
      .filter((page) => page.mk_slug?.current)
      .map((page) => ({ params: { slug: page.mk_slug!.current!.split('/') } }))

    if (currentPaths.length === 0) {
      // No more pages, break out of the loop
      break
    }

    allPaths = [...allPaths, ...currentPaths]
    offset += limit
  }

  return { paths: allPaths, fallback: 'blocking' }
}

export const getStaticProps = async ({ params, preview: isPreview }: GetStaticPropsContext) => {
  try {
    const slugArray = (params as { slug: Array<string> }).slug
    const slugPath = slugArray.join('/').toLowerCase()

    // If slug is uppercase in Sanity (unlikely due to validation there), page won't be found by getPageApi()
    let pageQueryResponse = await getPageApi(slugPath, !!isPreview)
    let page = pageQueryResponse.allMkMarketingPage[0]

    // If preview page no longer exists, get the actual page
    if (isPreview && !page) {
      pageQueryResponse = await getPageApi(slugPath, false)
      page = pageQueryResponse.allMkMarketingPage[0]
    }

    if (pageQueryResponse.allMkMarketingPage.length <= 0) {
      logger.log(`Slug: ${slugPath}  not in Sanity. Data: ${JSON.stringify(pageQueryResponse)}`)
      return {
        notFound: true,
        revalidate: 60,
      }
    }

    const cmsSlugObj = await getAllPagesSlugs()

    return { props: { page, cmsSlugObj }, revalidate: 60 }
  } catch (error) {
    const filename = path.basename(__filename)
    logger.log(`Error: ${error} File: ${filename}`)
    return {
      notFound: true,
      revalidate: 60,
    }
  }
}

export default Slug
